// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bkmks-js": () => import("./../src/pages/bkmks.js" /* webpackChunkName: "component---src-pages-bkmks-js" */),
  "component---src-pages-creating-js": () => import("./../src/pages/creating.js" /* webpackChunkName: "component---src-pages-creating-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listening-js": () => import("./../src/pages/listening.js" /* webpackChunkName: "component---src-pages-listening-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-reading-js": () => import("./../src/pages/reading.js" /* webpackChunkName: "component---src-pages-reading-js" */),
  "component---src-pages-teaching-js": () => import("./../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-pages-training-js": () => import("./../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-watching-js": () => import("./../src/pages/watching.js" /* webpackChunkName: "component---src-pages-watching-js" */),
  "component---src-pages-writing-js": () => import("./../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-bkmk-post-js": () => import("./../src/templates/bkmk-post.js" /* webpackChunkName: "component---src-templates-bkmk-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

